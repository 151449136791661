import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

function Contact() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    inquiryType: "general", // Hidden field with a default value
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.firstName.trim())
      newErrors.firstName = "First name is required";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required";
    if (!emailRegex.test(formData.email)) newErrors.email = "Invalid email";
    if (!phoneRegex.test(formData.phone))
      newErrors.phone = "Phone number must be 10 digits";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const requestData = {
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        phone: formData.phone,
        subject: "Contact Form Inquiry", // Assuming a default subject
        inquiryType: formData.inquiryType,
        message: formData.message,
      };

      try {
        const response = await fetch(
          "https://www.epe-india.com/contact-form.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        if (response.ok) {
          toast.success("Your message has been sent successfully!");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
            inquiryType: "Contact Us", // Reset hidden field
          });
        } else {
          toast.error(
            "There was an error sending your message. Please try again."
          );
        }
      } catch (error) {
        toast.error("There was a problem with the network. Please try again.");
      }
    }
  };

  return (
    <div>
      <Toaster position="bottom-center" />
      <div className="inner-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30446.479619944603!2d78.439352!3d17.468808!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99cd87dc821b%3A0x52cb1299b834de63!2sEPE%20Process%20Filters%20Accumulators%20Pvt%20Ltd!5e0!3m2!1sen!2sus!4v1726941053150!5m2!1sen!2sus"
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="Certifications-sec2-bg ">
        <div className="wrapper">
          <div className="contact-wrap">
            <div className="contact-1">
              <div className="contact-1-list ">
                <div className="contact-1-1">
                  <div className="icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="34"
                      viewBox="0 0 35 34"
                      fill="none"
                    >
                      <path
                        d="M17.5778 2.18762C11.777 2.18762 7.07031 6.42413 7.07031 11.6444C7.07031 20.0504 17.5778 31.6087 17.5778 31.6087C17.5778 31.6087 28.0854 20.0504 28.0854 11.6444C28.0854 6.42413 23.3787 2.18762 17.5778 2.18762ZM17.5778 16.8982C16.7466 16.8982 15.934 16.6517 15.2428 16.1898C14.5516 15.728 14.0129 15.0716 13.6948 14.3036C13.3767 13.5356 13.2934 12.6905 13.4556 11.8752C13.6178 11.0599 14.0181 10.311 14.6059 9.72318C15.1937 9.13537 15.9426 8.73507 16.7579 8.5729C17.5732 8.41073 18.4183 8.49396 19.1863 8.81208C19.9543 9.13019 20.6107 9.6689 21.0725 10.3601C21.5344 11.0513 21.7809 11.8639 21.7809 12.6952C21.7796 13.8095 21.3364 14.8778 20.5485 15.6658C19.7605 16.4537 18.6922 16.897 17.5778 16.8982Z"
                        fill="#052D71"
                      />
                    </svg>
                  </div>
                </div>
                <div className="contact-1-2">
                  <h6>Location</h6>
                  <p>
                    <b>Corporate Office:</b> Techni Towers, C-54/A, TSIIC,
                    Balanagar, Hyderabad - 500037, TS, India
                  </p>
                  {/* <p>
                    <b>Unit 1:</b> Techni Towers, C-54/A, TSIIC, Balanagar,
                    Hyderabad - 500037, TS, India
                  </p>
                  <p>
                    <b>Unit 2:</b> Techni Towers, C-54/A, TSIIC, Balanagar,
                    Hyderabad - 500037, TS, India
                  </p> */}
                </div>
              </div>
              <div className="contact-1-list ">
                <div className="contact-1-1">
                  <div className="icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="34"
                      viewBox="0 0 35 34"
                      fill="none"
                    >
                      <path
                        d="M7.23277 26.7052C6.58738 26.7052 6.04892 26.4895 5.61742 26.058C5.18591 25.6265 4.96968 25.0876 4.96875 24.4412V9.35521C4.96875 8.70981 5.18497 8.17136 5.61742 7.73985C6.04986 7.30834 6.58784 7.09212 7.23137 7.09119H27.9242C28.5687 7.09119 29.1067 7.30741 29.5382 7.73985C29.9697 8.17229 30.1859 8.71075 30.1868 9.35521V24.4426C30.1868 25.0871 29.9706 25.6255 29.5382 26.058C29.1057 26.4904 28.5677 26.7062 27.9242 26.7052H7.23277ZM17.5778 17.0607L28.7858 9.73208L28.3543 8.49219L17.5778 15.4972L6.80126 8.49219L6.36975 9.73208L17.5778 17.0607Z"
                        fill="#052D71"
                      />
                    </svg>
                  </div>
                </div>
                <div className="contact-1-2">
                  <h6>Email</h6>
                  <p>business@epe-india.com</p>
                </div>
              </div>
              <div className="contact-1-list ">
                <div className="contact-1-1">
                  <div className="icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                    >
                      <path
                        d="M10.0404 15.9432C12.0578 19.9081 15.3082 23.1584 19.273 25.1759L22.3552 22.0937C22.7475 21.7014 23.2939 21.5893 23.7842 21.7434C25.3534 22.2618 27.0346 22.542 28.7858 22.542C29.1574 22.542 29.5137 22.6896 29.7765 22.9523C30.0392 23.2151 30.1868 23.5714 30.1868 23.943V28.8465C30.1868 29.2181 30.0392 29.5744 29.7765 29.8372C29.5137 30.0999 29.1574 30.2475 28.7858 30.2475C22.4691 30.2475 16.4112 27.7382 11.9446 23.2716C7.47804 18.8051 4.96875 12.7471 4.96875 6.43042C4.96875 6.05885 5.11636 5.7025 5.37909 5.43976C5.64183 5.17702 5.99818 5.02942 6.36975 5.02942H11.2733C11.6448 5.02942 12.0012 5.17702 12.2639 5.43976C12.5267 5.7025 12.6743 6.05885 12.6743 6.43042C12.6743 8.18168 12.9545 9.86288 13.4728 11.432C13.627 11.9224 13.5149 12.4688 13.1226 12.861L10.0404 15.9432Z"
                        fill="#052D71"
                      />
                    </svg>
                  </div>
                </div>
                <div className="contact-1-2">
                  <h6>Phone</h6>
                  <p>
                    +91-40-23778803
                    <br />
                    +91-40-23778804
                  </p>
                </div>
              </div>
            </div>
            <div className="contact-2">
              <h1>Get in Touch</h1>
              <form onSubmit={handleSubmit} className="contact-form-sec-1">
                <div className="list-45">
                  <h5>First Name*</h5>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    className="text-field-4"
                  />
                  {errors.firstName && (
                    <p className="error">{errors.firstName}</p>
                  )}
                </div>
                <div className="list-45">
                  <h5>Last Name*</h5>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className="text-field-4"
                  />
                  {errors.lastName && (
                    <p className="error">{errors.lastName}</p>
                  )}
                </div>
                <div className="list-45">
                  <h5>Email*</h5>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email*"
                    className="text-field-4 w-45"
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="list-45">
                  <h5>Phone Number*</h5>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    className="text-field-4 w-45"
                  />
                  {errors.phone && <p className="error">{errors.phone}</p>}
                </div>
                <div className="list-100">
                  <h5>Message*</h5>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Type your message"
                    className="text-area-2"
                  ></textarea>
                </div>
                <input
                  type="hidden"
                  name="inquiryType"
                  value={formData.inquiryType}
                />
                <div className="list-100">
                  <button type="submit" className="button-1">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
