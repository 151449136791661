import React, { useState, useEffect } from "react";
import CareerImg1 from "./images/career-sec1-1.png";
import CareerImg from "./images/Awards-img-2.png";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import { useParams } from "react-router-dom";
import ContactPatch3 from "./images/Awards-img-2.png";
import Timestamp from "react-timestamp";
import DOMPurify from "dompurify";

// import jobData from "./SepareteJobData";

function Careers() {
  const [careerData, setCareerData] = useState([]);

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [qualification, setQualification] = useState("");
  const [experience, setExperience] = useState("");
  const [jobCategory, setJobCategory] = useState("");
  const [address, setAddress] = useState("");
  const [otherinformation, setOtherinformation] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [resume, setResume] = useState(null);
  const [careerJobId, setJobId] = useState("");
  const [job, setJob] = useState(null);
  const [errors, setErrors] = useState({});

  const handleChange = (e, name) => {
    switch (name) {
      case "fname":
        setFname(e.target.value);
        break;
      case "lname":
        setLname(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
      case "qualification":
        setQualification(e.target.value);
        break;
      case "experience":
        setExperience(e.target.value);
        break;
      case "address":
        setAddress(e.target.value);
        break;
      case "otherinformation":
        setOtherinformation(e.target.value);
        break;
      case "jobCategory":
        setJobCategory(e.target.value); // Update jobCategory state on change
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Required fields validation
    if (!fname) newErrors.fname = "First Name is required.";
    if (!lname) newErrors.lname = "Last Name is required.";
    if (!email) newErrors.email = "Email is required.";
    if (!phone) newErrors.phone = "Phone Number is required.";
    if (!qualification) newErrors.qualification = "Qualification is required.";
    if (!experience) newErrors.experience = "Experience is required.";
    if (!jobCategory) newErrors.jobCategory = "Job Category is required.";
    if (!address) newErrors.address = "Address is required.";
    if (!resume) newErrors.resume = "Resume upload is required.";

    // Email format validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailPattern.test(email)) {
      newErrors.email = "Invalid email format.";
    }

    // Phone number format validation (example: allow digits only, adjust as needed)
    const phonePattern = /^\d{10}$/; // Assuming 10 digits for phone
    if (phone && !phonePattern.test(phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    const uploadFile = async () => {
      try {
        const formData = new FormData();
        const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
        const fileWithTimestamp = new File(
          [resume],
          `${timestamp}_${resume.name}`,
          { type: resume.type }
        );

        formData.append("resumefile", fileWithTimestamp);
        formData.append("job_id", careerJobId);

        const response = await fetch(
          "https://www.epe-india.com/career-file-upload.php",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("File Upload Response:", data);
          if (data && data.error) {
            console.error("Server Error:", data.error);
            alert(`Upload Error: ${data.error}`);
            return null;
          } else {
            console.log("Uploaded file name:", data.filename);
            return data.filename;
          }
        } else {
          const errorText = await response.text();
          console.error("Server response:", errorText);
          // alert(`Upload failed: ${errorText}`);
          throw new Error("Failed to upload file");
        }
      } catch (error) {
        console.error("Upload error:", error);
        // alert("An error occurred while uploading the file. Please try again.");
        return null;
      }
    };

    const filename = await uploadFile();

    if (!filename) {
      alert("File upload failed. Please try again.");
      return;
    }

    const dataObj = {
      fname,
      lname,
      email,
      phone,
      qualification,
      experience,
      job_category: jobCategory,
      address,
      message: otherinformation,
      // job_id: careerJobId,
      job_id: 0,
      resumefile: filename,
    };
    console.log("dataObj", dataObj);

    const postData = async () => {
      try {
        const response = await fetch(
          "https://www.epe-india.com/career-form.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataObj),
          }
        );

        alert("response", response);
        console.log("Responseee", response);
        if (response.ok) {
          const data1 = await response.json();
          console.log("Response from post", data1);
          // alert("Form submitted successfully!");
          toast.success("Form submitted successfully!");

          setFname("");
          setLname("");
          setEmail("");
          setPhone("");
          setQualification("");
          setExperience("");
          setJobCategory("");
          setAddress("");
          setOtherinformation("");
          setResume(null);
        } else {
          const errorText = await response.text();
          console.error("Server response:", errorText);
          // alert(`Form submission failed: ${errorText}`);
          toast.error("Form submission failed. Please try again.");
          throw new Error("Failed to submit form");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("An error occurred. Please try again later.");
        // alert("An error occurred while submitting the form. Please try again.");
      }
    };

    await postData();
  };

  const onChangeFileUpload = (e) => {
    const file = e.target.files[0];
    setResume(file);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // sw://nfinity.psmprojects.net/career.php

  useEffect(() => {
    const fetchCarrerData = async () => {
      try {
        const response = await fetch("https://www.epe-india.com/career.php");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        if (jsonData) {
          const fetchedData = jsonData.map((eachItem) => ({
            id: eachItem.id,
            job_title: eachItem.job_title,
            company_name: eachItem.company_name,
            location: eachItem.location,
            description: eachItem.description,
          }));
          console.log("FETCHEDDATA", fetchedData);
          setCareerData(fetchedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchCarrerData();
  }, []);

  return (
    <div>
      <Toaster position="bottom-center" />
      <div className="inner-banner career-banner-1">
        <div className="wrapper">
          <h1>Join Us </h1>

          <h6></h6>
          <button className="button-1">View All Positions</button>
        </div>
      </div>

      <div className="inner-page-bg-1">
        <div className="wrapper">
          {/* <h1>Careers</h1> */}
          {/* <div className="career-sec1-wrap">
            <div className="career-sec1-2">
              <h1>
                OPEN POSITIONS AT <span>EPE</span> INDIA
              </h1>

              <p>Will update you shortly..</p>

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div> */}
          <div>
            {/* <ul>
              {careerData.map((job) => (
                <li key={job.id}>
                  <div className="current-openings-box">
                    <div className="cob-1">
                      <h2>
                        <img src={CareerImg} alt="InnerShapeOne" />{" "}
                        {job.job_title}
                      </h2>
                      <p>
                        <b>Company :</b>
                        {job.company}
                      </p>
                      <p>
                        <b>Location :</b> ({job.location})
                      </p>

                      <p>
                        <b>Description :</b>
                        <span
                          dangerouslySetInnerHTML={{ __html: job.description }}
                        />
                      </p>
                    </div>
                    <div className="cob-2">
                      <div className="cob-2-1">
                        <Link
                          to={`/career-details/${job.id}`}
                          onClick={scrollToTop}
                        >
                          <strong>Read more</strong>
                        </Link>
                      </div>
                      <div className="cob-2-2">
                        <button class="button-4">Apply Now</button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul> */}

            <br />
            <br />
            <div className="career-sec2-wrap">
              <h3>
                OPEN POSITIONS AT <span>EPE</span> INDIA
              </h3>
              <div className="career-btn-1">
                <ul>
                  {careerData.map((job) => (
                    <li key={job.id}>
                      <div className="current-openings-box">
                        <h4>{job.job_title}</h4>
                        <div className="career-rm-1">
                          <Link
                            to={`/career-details/${job.id}`}
                            onClick={scrollToTop}
                          >
                            <svg
                              width="37"
                              height="37"
                              viewBox="0 0 37 37"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="18.1322"
                                cy="18.1322"
                                r="16.876"
                                fill="#1E3488"
                                fill-opacity="0.97"
                                stroke="white"
                                stroke-width="2.5124"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M26.0338 17.4057C26.1679 17.5399 26.2432 17.7219 26.2432 17.9115C26.2432 18.1012 26.1679 18.2831 26.0338 18.4173L20.3079 24.1433C20.2424 24.2136 20.1633 24.27 20.0755 24.3091C19.9877 24.3482 19.893 24.3693 19.7969 24.371C19.7008 24.3727 19.6053 24.355 19.5162 24.319C19.427 24.283 19.3461 24.2294 19.2781 24.1615C19.2102 24.0935 19.1566 24.0125 19.1206 23.9234C19.0846 23.8343 19.0669 23.7388 19.0686 23.6427C19.0703 23.5466 19.0913 23.4518 19.1304 23.364C19.1696 23.2762 19.226 23.1972 19.2963 23.1317L23.8007 18.6273H10.2588C10.069 18.6273 9.88693 18.5519 9.75271 18.4176C9.61848 18.2834 9.54307 18.1014 9.54307 17.9115C9.54307 17.7217 9.61848 17.5396 9.75271 17.4054C9.88693 17.2712 10.069 17.1958 10.2588 17.1958H23.8007L19.2963 12.6914C19.226 12.6258 19.1696 12.5468 19.1304 12.459C19.0913 12.3712 19.0703 12.2764 19.0686 12.1803C19.0669 12.0842 19.0846 11.9888 19.1206 11.8997C19.1566 11.8105 19.2102 11.7296 19.2781 11.6616C19.3461 11.5936 19.427 11.5401 19.5162 11.5041C19.6053 11.4681 19.7008 11.4504 19.7969 11.4521C19.893 11.4538 19.9877 11.4748 20.0755 11.5139C20.1633 11.553 20.2424 11.6094 20.3079 11.6798L26.0338 17.4057Z"
                                fill="white"
                              />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-styles">
        <div className="form-sec-main ">
          <div className="wrapper">
            <h6 className="text-center-1">
              If you are looking for a job which is not listed above submit the
              below form and our team will get back to you
            </h6>
            {/* <form encType="multipart/form-data">
              <div className="form-sec-1">
                <b>Full Name</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Enter Your Name"
                />
              </div>
              <div className="form-sec-1">
                <b>Email Id</b>
                <input
                  className="text-1"
                  placeholder="Enter Your Email*"
                  type="text"
                />
              </div>
              <div className="form-sec-1">
                <b>Phone Number</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Your Phone No.*"
                />
              </div>
              <div className="form-sec-1">
                <b>Qualification</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Enter Education Details"
                />
              </div>
              <div className="form-sec-1">
                <b>Experience</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Total Experience"
                />
              </div>

              <div className="form-sec-1">
                <b>Job Role*</b>
                <input className="text-1" />

                <input type="hidden" name="careerJobId" />
              </div>

              <div className="form-sec-1 w-100">
                <b>Message</b>
                <textarea
                  className="textarea-1"
                  placeholder="Message"
                ></textarea>
              </div>
              <div className="form-sec-1 w-100">
                <b>Upload Resume*</b>
                <input
                  type="file"
                  className="text-2"
                  name="resume"
                  placeholder="Subject"
                />
              </div>
              <div className="form-sec-1 w-100">
                <button type="submit" value="Submit" className="button-1">
                  Submit
                </button>
              </div>
            </form> */}

            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="form-sec-1">
                <b>First Name</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Enter Your First Name"
                  value={fname}
                  name="fname"
                  id="fname"
                  onChange={(e) => handleChange(e, "fname")}
                />
                {errors.fname && <span className="error">{errors.fname}</span>}
              </div>
              <div className="form-sec-1">
                <b>Last Name</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Enter Your Last Name"
                  value={lname}
                  name="lname"
                  id="lname"
                  onChange={(e) => handleChange(e, "lname")}
                />
                {errors.lname && <span className="error">{errors.lname}</span>}
              </div>
              <div className="form-sec-1">
                <b>Email Id</b>
                <input
                  className="text-1"
                  placeholder="Enter Your Email*"
                  type="email"
                  value={email}
                  name="email"
                  id="email"
                  onChange={(e) => handleChange(e, "email")}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <div className="form-sec-1">
                <b>Phone Number</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Your Phone No.*"
                  value={phone}
                  name="phone"
                  id="phone"
                  onChange={(e) => handleChange(e, "phone")}
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>
              <div className="form-sec-1">
                <b>Qualification</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Your Qualification"
                  value={qualification}
                  name="qualification"
                  id="qualification"
                  onChange={(e) => handleChange(e, "qualification")}
                />
                {errors.qualification && (
                  <span className="error">{errors.qualification}</span>
                )}
              </div>
              <div className="form-sec-1">
                <b>Experience</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Your Experience"
                  value={experience}
                  name="experience"
                  id="experience"
                  onChange={(e) => handleChange(e, "experience")}
                />
                {errors.experience && (
                  <span className="error">{errors.experience}</span>
                )}
              </div>
              <div className="form-sec-1">
                <b>Job Category</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Enter Job Category"
                  value={jobCategory} // Bind to jobCategory state
                  name="jobCategory"
                  id="jobCategory"
                  onChange={(e) => handleChange(e, "jobCategory")} // Update jobCategory on change
                />
                {errors.jobCategory && (
                  <span className="error">{errors.jobCategory}</span>
                )}
              </div>
              <div className="form-sec-1">
                <b>Address</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Your Address"
                  value={address}
                  name="address"
                  id="address"
                  onChange={(e) => handleChange(e, "address")}
                />
                {errors.address && (
                  <span className="error">{errors.address}</span>
                )}
              </div>
              <div className="form-sec-1 w-100">
                <b>Other Information</b>
                <textarea
                  className="textarea-1"
                  placeholder="Message"
                  value={otherinformation}
                  name="otherinformation"
                  id="otherinformation"
                  onChange={(e) => handleChange(e, "otherinformation")}
                />
              </div>

              <div className="form-sec-1 w-100">
                <b>Upload Resume</b>
                <input
                  className="text-2"
                  type="file"
                  name="resume"
                  id="resume"
                  onChange={onChangeFileUpload}
                />
                {errors.resume && (
                  <span className="error">{errors.resume}</span>
                )}
              </div>

              <div className="form-sec-1 w-100">
                <button type="submit" value="Submit" className="button-1">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Careers;
