import React, { useState, useEffect } from "react";

import { NavLink, Link } from "react-router-dom";
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import "../Styles.css";
import "./header.css";

import logo from "../images/logo.svg";
import logopng from "../images/logopng.png";

//import logo from "../../img/logo.png";
const Header = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleListItemClick = () => {
    toggleVisibility();
  };

  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);

  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }
  const [isMenuSubMenu, setMenuSubMenu] = useState(false);
  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };
  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className={scrolled ? "navbar scrolled" : "navbar"}>
        <div className="wrapper">
          <div className="header-new-wrap">
            <div className="header-new-logo">
              <NavLink exact activeClassName="is-active" to="/">
                <img
                  src={logopng}
                  alt="EPE"
                  className={scrolled ? "logo scrolled" : "logo"}
                />
              </NavLink>
            </div>
            <div className="header-new-menu">
              <nav className="main-nav ">
                {/* Responsive Menu Button */}
                {isResponsiveclose === true ? (
                  <>
                    <span
                      className="menubar__button"
                      style={{ display: "none" }}
                      onClick={toggleClass}
                    >
                      <FiXCircle />
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="menubar__button"
                      style={{ display: "none" }}
                      onClick={toggleClass}
                    >
                      <FiAlignRight />
                    </span>
                  </>
                )}
                <ul className={boxClass.join(" ")}>
                  <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/`}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li
                    onClick={toggleSubmenu}
                    className="menu-item sub__menus__arrows"
                  >
                    <Link to="#">
                      About Us <FiChevronDown />
                    </Link>

                    <ul className={boxClassSubMenu.join(" ")}>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/CompanyOverview`}
                        >
                          Company Overview
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/QualityPromise`}
                        >
                          Quality Promise
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/EnvironmentManagement`}
                        >
                          EHS Policy
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Certifications`}
                        >
                          Certifications
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/AwardsAchievement`}
                        >
                          Awards & Achievement
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li
                    onClick={toggleSubmenu}
                    className="menu-item sub__menus__arrows"
                  >
                    <Link to="/Products">
                      Products <FiChevronDown />
                    </Link>
                    <ul className={boxClassSubMenu.join(" ")}>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/FilterPage`}
                        >
                          Filters
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Accumlators`}
                        >
                          Accumulators
                        </NavLink>
                      </li>

                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Switches`}
                        >
                          Switches & Sensors
                        </NavLink>
                      </li>
                      <li onClick={scrollToTop}>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/Valves`}
                        >
                          Valves
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li
                    onClick={toggleSubmenu}
                    className="menu-item sub__menus__arrows"
                  >
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`https://software.epe-india.com`}
                    >
                      Product Calculator
                    </NavLink>
                    {/* <Link to="#">
                      Software <FiChevronDown />
                    </Link>
                    <ul className={boxClassSubMenu.join(" ")}>
                      <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/BladderAccumlators`}
                        >
                          Bladder Accumlators
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/PulseAccumlators`}
                        >
                          Pulse Accumlators
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/PistonAccumlators`}
                        >
                          Piston Accumlators
                        </NavLink>
                      </li>
                    </ul> */}
                  </li>

                  <li className="menu-item" onClick={scrollToTop}>
                    <NavLink
                      exact
                      activeClassName="is-active"
                      onClick={toggleClass}
                      to={`/Careers`}
                    >
                      Careers
                    </NavLink>
                  </li>

                  <li className="menu-item, contactbtn1">
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/Contact`}
                    >
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
