import React, { useState } from "react";

function GetinTouch() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    inquiryType: "general", // Hidden field with a default value
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.firstName.trim())
      newErrors.firstName = "First name is required";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required";
    if (!emailRegex.test(formData.email)) newErrors.email = "Invalid email";
    if (!phoneRegex.test(formData.phone))
      newErrors.phone = "Phone number must be 10 digits";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const requestData = {
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        phone: formData.phone,
        subject: "Contact Form Inquiry", // Assuming a default subject
        inquiryType: formData.inquiryType,
        message: formData.message,
      };

      try {
        const response = await fetch(
          "https://www.epe-india.com/contact-form.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        if (response.ok) {
          alert("Your message has been sent successfully!");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
            inquiryType: "Contact Us", // Reset hidden field
          });
        } else {
          alert("There was an error sending your message. Please try again.");
        }
      } catch (error) {
        alert("There was a problem with the network. Please try again.");
      }
    }
  };

  return (
    <div>
      <div className="get-in-touch-bg">
        <div className="get-in-touch-form">
          <div className="heading-2">
            <h4>
              <span>Contact Us</span>
            </h4>
            <h3 className="blue-color">Get in Touch</h3>
            {/* <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="text-field-1"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <span className="error">{errors.name}</span>}

              <input
                type="text"
                name="email"
                placeholder="E mail"
                className="text-field-1"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <span className="error">{errors.email}</span>}

              <input
                type="text"
                name="phone"
                placeholder="Contact Number"
                className="text-field-1"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <span className="error">{errors.phone}</span>}

              <input
                type="text"
                name="subject"
                placeholder="Subject"
                className="text-field-1"
                value={formData.subject}
                onChange={handleChange}
              />

              <textarea
                name="message"
                placeholder="Message"
                className="text-area-1"
                value={formData.message}
                onChange={handleChange}
              ></textarea>

              
              <input
                type="hidden"
                name="inquiryType"
                value={formData.inquiryType}
              />

              <button type="submit" className="button-1">
                Send
              </button>
            </form> */}

            <form onSubmit={handleSubmit} className="contact-form-sec-1">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Name"
                className="text-field-1"
              />
              {errors.firstName && <p className="error">{errors.firstName}</p>}

              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
                className="text-field-1"
              />
              {errors.lastName && <p className="error">{errors.lastName}</p>}

              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email*"
                className="text-field-1"
              />
              {errors.email && <p className="error">{errors.email}</p>}

              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone Number"
                className="text-field-1"
              />
              {errors.phone && <p className="error">{errors.phone}</p>}

              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Type your message"
                className="text-area-1"
              ></textarea>

              <input
                type="hidden"
                name="inquiryType"
                value={formData.inquiryType}
              />
              <div className="list-100">
                <button type="submit" className="button-1">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetinTouch;
